/* eslint-disable */
import { checkPermission } from '@/utils'

export const permissionList = {
  import: checkPermission(['externaladmin:productTemplate:templateManage:import']),
  disable: checkPermission(['externaladmin:productTemplate:templateManage:disable']),
  reUpload: checkPermission(['externaladmin:productTemplate:templateManage:upload']),
  del: checkPermission(['externaladmin:productTemplate:templateManage:del'])
}

export const option = {
  column: [
    {
      label: '系统模板名称',
      prop: 'templateName',
      search: true
    },
    // {
    //   label: '所属平台',
    //   prop: 'platformId',
    //   hide: true,
    //   search: true,
    //   type: 'select'
    // },
    // {
    //   label: '授权时间',
    //   prop: 'authTime',
    //   hide: true,
    //   search: true,
    //   type: 'daterange'
    // },
    {
      label: '状态',
      prop: 'isDisabled',
      dicData: [
        {
          label: '禁用',
          value: 0
        },
        {
          label: '启用',
          value: 1
        }
      ]
    },
    {
      label: '创建时间',
      prop: 'createTime'
    }
  ]
}